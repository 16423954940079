import request from '@/utils/request'

let baseUrl = ''
// baseUrl = '/xf'

/**
 * 获取token
 */
export async function getToken(data) {
  return await request({
    url: baseUrl + '/mall/gateway',
    method: 'POST',
    data
  })
}

export async function getlist(data) {
  return await request({
    url: baseUrl + '/tfood/gateway',
    method: 'POST',
    data
  })
}

export async function mallApi(data) {
  return await request({
    url: baseUrl + '/mall/gateway',
    method: 'POST',
    data
  })
}

export async function tfoodApi(data) {
  return await request({
    url: baseUrl + '/tfood/gateway',
    method: 'POST',
    data
  })
}

/**
 * 图片上传
 * @param {Array} arr  图片base64数组
 * @param {String} status  forever 永远保存
 */
export function uploadPhoto(arr, status = 'forever') {
  let data = {
    trxCode: 'MS001',
    pics: arr,
    status
  }
  return request({
    url: baseUrl + '/tfood/gateway',
    method: 'POST',
    data
  })
}
