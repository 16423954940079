/* 默认配置 */
const defaultConfig = {
  // 底部tabBar图标
  tabBar_homeSel: require('../assets/bannder/home-select.png'),
  tabBar_home: require('../assets/bannder/home-notselect.png'),
  tabBar_orderSel: require('../assets/bannder/order-select.png'),
  tabBar_order: require('../assets/bannder/order-notselect.png'),
  tabBar_posterSel: require('../assets/bannder/poster_select.jpg'),
  tabBar_poster: require('../assets/bannder/poster_notselect.jpg'),
  tabBar_userSel: require('../assets/bannder/user-select.png'),
  tabBar_user: require('../assets/bannder/user-noselect.png'),

  emptyShop: require('../assets/home/notshop.png'), // 地区无店铺提示图片
  homeDefaultCarousel: require('../assets/home/defaultCarousel.jpg'), // 首页默认轮播图


  defaultUserImg: require('../assets/user/DefaultUserImg.png'), // 用户默认头像

  LoginLogoImg: require('../assets/user/loginFood.png') // 登录页面logo


}
const customizeMap = {
  /* 张总-众邦网络 */
  'A10012609363': {
    // 底部tabBar图标
    tabBar_homeSel: require('../assets/ZBWL/bannder/home-select.png'),
    tabBar_home: require('../assets/ZBWL/bannder/home-notselect.png'),
    tabBar_orderSel: require('../assets/ZBWL/bannder/order-select.png'),
    tabBar_order: require('../assets/ZBWL/bannder/order-notselect.png'),
    tabBar_userSel: require('../assets/ZBWL/bannder/user-select.png'),
    tabBar_user: require('../assets/ZBWL/bannder/user-noselect.png'),

    emptyShop: require('../assets/ZBWL/home/notshop.png'), // 地区无店铺提示图片
    homeDefaultCarousel: require('../assets/ZBWL/home/defaultCarousel.jpg'), // 首页默认轮播图


    defaultUserImg: require('../assets/ZBWL/user/DefaultUserImg.jpg'), // 用户默认头像

    LoginLogoImg: require('../assets/ZBWL/user/DefaultUserImg.jpg') // 登录页面logo
  }
}

const agentCustomize = function () {
  let agentId = localStorage.getItem('agent')
  let item = customizeMap[agentId]
  return Object.assign({}, defaultConfig, item)
}

module.exports = agentCustomize()