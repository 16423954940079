<template>
  <div class="tab-box">
    <div class="mp-box mainBgColor-plain" v-if="agentConfig.MP_CODE_URL && login && showMp">
      <van-icon name="cross" class="grey" @click="showMp = false" />
      <div class="flex-1 f34 margin-lr2 ">关注{{agentConfig.bwcName}}公众号, 享专属服务</div>
      <div class="mainBut f30" @click="goMp()">立即关注</div>
    </div>

    <div class="tabbar">
      <template v-if="customZhang">
        <div class="items" v-show="!item.disabled" v-for="(item, index) in tabbaritem" :key="index"
          @click="change(item.path)">
          <img :src="item.select" class="items-img" v-if="$route.path === item.path">
          <img :src="item.notselect" class="items-img" v-else>

          <div class="items-text" :class="$route.path === item.path?'active':''">
            <span>{{item.text}}</span>
          </div>

          <span class="point" v-if="item.path === '/orderList' && orderNum > 0">
            {{orderNum}}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="items" :class="$route.path === '/'?'active':''" @click="change('/')">
          <div class="page-icon2 home"></div>
          <div class="items-text">主页</div>
        </div>
        <div v-if="agentId === 'A10016110294'" class="items" :class="$route.path === '/customElm'?'active':''"
          @click="change('/customElm')">
          <div class="elm-icon"></div>
          <div class="items-text">立减餐</div>
        </div>
        <div class="items" :class="$route.path === '/orderList'?'active':''" @click="change('/orderList')">
          <van-badge v-if="orderNum > 0" :content="orderNum" max="9">
            <div class="page-icon2 order"></div>
            <div class="items-text">订单</div>
          </van-badge>
          <div v-else>
            <div class="page-icon2 order"></div>
            <div class="items-text">订单</div>
          </div>
        </div>
        <div class="items" :class="$route.path === '/ticky'?'active':''" @click="change('/ticky')">
          <div class="page-icon2 ticky"></div>
          <div class="items-text">海报</div>
        </div>
        <div class="items" :class="$route.path === '/team'?'active':''" v-if="agentConfig.BONUS_TYPE ==='Y'"
          @click="change('/team')">
          <div class="page-icon2 money"></div>
          <div class="items-text">赚钱</div>
        </div>
        <div class="items" :class="$route.path === '/user'?'active':''" @click="change('/user')">
          <div class="page-icon2 user"></div>
          <div class="items-text">我的</div>
        </div>
      </template>
    </div>

    <van-popup v-model="showGroupImg">
      <img class="groupImg" :src="groupImgUrl">
    </van-popup>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getlist } from '../../api'
const agentCustomize = require('@/utils/agentCustomize')

const tabbaritem = [
  {
    select: agentCustomize.tabBar_homeSel,
    notselect: agentCustomize.tabBar_home,
    path: '/',
    text: '主页',
  },
  // {
  //   select: require('../../assets/bannder/shop_active.png'),
  //   notselect: require('../../assets/bannder/shop.png'),
  //   path: '/shopList',
  //   text: '商家',
  // },
  {
    select: agentCustomize.tabBar_orderSel,
    notselect: agentCustomize.tabBar_order,
    path: '/orderList',
    text: '订单',
  },
  {
    select: agentCustomize.tabBar_posterSel,
    notselect: agentCustomize.tabBar_poster,
    path: '/ticky',
    text: '海报',
    disabled: localStorage.getItem('agent') === 'A10012609363'
  },
  {
    select: agentCustomize.tabBar_userSel,
    notselect: agentCustomize.tabBar_user,
    path: '/user',
    text: '我的',
  },
]

export default {
  name: 'tabbaritem',
  data() {
    return {
      tabbaritem: tabbaritem,
      agentId: localStorage.getItem('agent'),
      login: false,
      showMp: true,
      showGroupImg: false,
      groupImgUrl: false,
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'orderNum', 'customZhang', 'agentConfig']),
  },
  created() {
    this.login = localStorage.getItem('H5token')
  },
  methods: {
    ...mapActions('user', ['getUserInfo', 'getSysName']),

    change(path) {
      this.$router.replace(path)
    },

    // 展示公众号二维码
    goMp() {
      if (!this.agentConfig.MP_CODE_URL) {
        this.$toast('平台未设置')
        return
      }
      this.groupImgUrl = this.agentConfig.MP_CODE_URL
      this.showGroupImg = true
    },
  },
}
</script>

<style lang='less' scoped>
.tab-box {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.mp-box {
  display: flex;
  align-items: center;
  padding: 1vw 4vw;

  .mainBut {
    border-radius: 99px;
  }
}
.tabbar {
  background-color: #ffffff;
  border-top: 1px solid #eee;
  box-shadow: 0px -1px 1px rgba(150, 150, 150, 0.08);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2vw 0;
}
.page-icon2 {
  width: 21px;
  height: 21px;
}
.items-img {
  width: 6vw;
}
.items {
  position: relative;
  padding: 0 3vw;
  text-align: center;

  .page-icon2.home {
    background-position: -251px -260px;
  }
  .page-icon2.order {
    background-position: -251px -297px;
  }
  .page-icon2.ticky {
    background-position: -251px -333px;
  }
  .page-icon2.money {
    background-position: -251px -368px;
  }
  .page-icon2.user {
    background-position: -251px -410px;
  }
  .elm-icon {
    display: inline-block;
    background-image: url('../../assets/bannder/elm-notselect.png');
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .items-text {
    font-size: 12px;
    color: #666;
  }
}

.items.active {
  .elm-icon {
    background-image: url('../../assets/bannder/elm-select.png');
    width: 24px;
    height: 24px;
    margin-bottom: 0px;
  }
  .items-text {
    color: #444;
    font-weight: bold;
  }
}
.groupImg {
  width: 70vw;
}
.point {
  position: absolute;
  top: -2px;
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #fff;
  background: red;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
}
.redThemes .items.active {
  .page-icon2.home {
    background-position: -212px -260px;
  }
  .page-icon2.order {
    background-position: -212px -297px;
  }
  .page-icon2.ticky {
    background-position: -212px -333px;
  }
  .page-icon2.money {
    background-position: -212px -368px;
  }
  .page-icon2.user {
    background-position: -212px -410px;
  }
}
.defaultThemes .items.active {
  .page-icon2.home {
    background-position: -79px -260px;
  }
  .page-icon2.order {
    background-position: -79px -297px;
  }
  .page-icon2.ticky {
    background-position: -79px -333px;
  }
  .page-icon2.money {
    background-position: -79px -368px;
  }
  .page-icon2.user {
    background-position: -79px -410px;
  }
}
.cyanThemes .items.active {
  .page-icon2.home {
    background-position: -120px -260px;
  }
  .page-icon2.order {
    background-position: -120px -297px;
  }
  .page-icon2.ticky {
    background-position: -120px -333px;
  }
  .page-icon2.money {
    background-position: -120px -368px;
  }
  .page-icon2.user {
    background-position: -120px -410px;
  }
}
.blueThemes .items.active {
  .page-icon2.home {
    background-position: -170px -260px;
  }
  .page-icon2.order {
    background-position: -170px -297px;
  }
  .page-icon2.ticky {
    background-position: -170px -333px;
  }
  .page-icon2.money {
    background-position: -170px -368px;
  }
  .page-icon2.user {
    background-position: -170px -410px;
  }
}
.orangeThemes .items.active {
  .page-icon2.home {
    background-position: -31px -260px;
  }
  .page-icon2.order {
    background-position: -31px -297px;
  }
  .page-icon2.ticky {
    background-position: -31px -333px;
  }
  .page-icon2.money {
    background-position: -31px -368px;
  }
  .page-icon2.user {
    background-position: -31px -410px;
  }
}
</style>
