import Vue from 'vue'
import { tfoodApi, mallApi } from '@/api'
import { Toast } from 'vant'
import { judgeTerrace } from '@/utils/utils'
import wx from 'weixin-js-sdk'

const state = {
  agentId: localStorage.getItem('agent'),
  versions: localStorage.getItem('loginType') || 'H5', // H5    WeChat-公众号   applet-小程序
  custMsg: {}, // 用户信息
  location: {
    longitude: '116.40717', // 经度
    latitude: '39.90469' // 纬度
  },
  address: '',
  cityName: '定位中',
  mapKey: '2T5BZ-G5MKX-LYC4D-7LIKS-WI4CS-73BKI', // 腾讯定位SDK-key
  orderNum: 0, // 待处理订单数量
  agentConfig: {}, // 租客配置
  locationCity: '', // 用户所在当前城市
  customZhang: false, // 定制版张总
  isApp: false,
  theme: 'defaultThemes',
  themesMap: [
    { value: 'defaultThemes', label: '默认', color: '#9E7BFF' },
    { value: 'cyanThemes', label: '春苏', color: '#00B9C6' },
    { value: 'blueThemes', label: '夏凉', color: '#3B75FF' },
    { value: 'orangeThemes', label: '秋意', color: '#FF6C25' },
    { value: 'redThemes', label: '暖冬', color: '#F26868' }
  ],
  appConfigJson: {
    domainAgentId: []
  }
}

const mutations = {
  SET_THEME: (state, theme) => {
    state.theme = theme
  },
  SET_CUSTMSG: (state, custMsg) => {
    state.custMsg = custMsg
  },
  SET_VERSIONS: (state, versions) => {
    state.versions = versions
  },
  SET_LOCATION: (state, location) => {
    state.location = location
  },
  SET_ADDRESS: (state, address) => {
    state.address = address
  },
  SET_CITYNAME: (state, cityName) => {
    state.cityName = cityName
  },
  SET_ORDERNUM: (state, orderNum) => {
    state.orderNum = orderNum
  },
  SET_AGENTID: (state, agentId) => {
    state.agentId = agentId
  },
  SET_AGENTCONFIG: (state, agentConfig) => {
    state.agentConfig = agentConfig
  },
  SET_LOCATIONCITY: (state, locationCity) => {
    state.locationCity = locationCity
  },
  SET_CUSTOMZHANG: (state, customZhang) => {
    state.customZhang = customZhang
  },
  SET_ISAPP: (state, isApp) => {
    state.isApp = isApp
  },
  SET_APPCONFIGJSON: (state, appConfigJson) => {
    state.appConfigJson = appConfigJson
  }
}

const actions = {
  // 获取用户信息
  async getUserInfo(context) {
    await mallApi({
      trxCode: 'XF171'
    }).then((res) => {
      // 获取定位信息
      // const custMsg = res.custMsg || {}
      // if (custMsg.latitude && custMsg.longitude && custMsg.cityName && custMsg.address) {
      //   let location = {}
      //   location.longitude = custMsg.longitude
      //   location.latitude = custMsg.latitude
      //   context.commit('SET_LOCATION', location)
      //   context.commit('SET_CITYNAME', custMsg.cityName)
      //   context.commit('SET_ADDRESS', custMsg.address)
      // } else {
      //   context.dispatch('getLocation')
      // }

      if (res.custMsg) {
        context.commit('SET_CUSTMSG', res.custMsg)
        context.dispatch('getOrderNum')
      }

      // if (custMsg.theme && custMsg.theme != 'DEFAULT') {
      //   document.body.className = custMsg.theme
      //   localStorage.setItem('theme', custMsg.theme)
      // }
    })
  },

  // 获取待提交订单数量
  async getOrderNum(context) {
    const custMsg = context.state.custMsg
    let params = {
      trxCode: 'MS107',
      openId: custMsg.openIdMp,
      page: 1,
      pageSize: 20,
      statusList: ['LOCK', 'NOPASS']
    }
    tfoodApi(params).then((res) => {
      if (res.orderFoodStatusNum) {
        let num = res.orderFoodStatusNum.reduce((prev, item) => {
          return prev + item.num
        }, 0)
        context.commit('SET_ORDERNUM', num)
      }
    })
  },

  // 获取定位
  async getLocation(context) {
    let location = {}
    const key = context.state.mapKey
    // H5版及公众号版
    if (context.state.versions === 'H5' || context.state.versions === 'WeChat') {
      var geolocation = new qq.maps.Geolocation(key, 'myapp')
      geolocation.getLocation(
        (position) => {
          descLocation(position.lat, position.lng)
        },
        (result) => {
          Toast(`定位失败${JSON.stringify(result)}`)
        }
      )
    }

    if (context.state.versions === 'applet') {
      descLocation(context.state.location.latitude, context.state.location.longitude)
    }

    // app兼容定位方法
    // 扩展API加载完毕后调用onPlusReady回调函数
    document.addEventListener('plusready', onPlusReady, false)
    // 扩展API加载完毕，现在可以正常调用扩展API
    function onPlusReady() {
      plus.geolocation.getCurrentPosition(
        function (p) {
          // alert('Geolocation\nLatitude:' + p.coords.latitude + '\nLongitude:' + p.coords.longitude + '\nAltitude:' + p.coords.altitude);
          descLocation(p.coords.latitude, p.coords.longitude)
        },
        function (e) {
          alert('Geolocation error: ' + e.message)
        }
      )
    }

    // 获取经纬度详细信息
    function descLocation(lat, lng) {
      Vue.prototype
        .$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
          location: `${lat},${lng}`,
          output: 'jsonp', // output必须jsonp   不然会超时
          key
        })
        .then((res) => {
          if (res.result) {
            location.longitude = res.result.location.lng
            location.latitude = res.result.location.lat
            context.commit('SET_LOCATION', location)
            context.commit('SET_CITYNAME', res.result.ad_info.city)
            context.commit('SET_ADDRESS', res.result.formatted_addresses.recommend)
          }

          // 提交定位记录
          // if (localStorage.getItem('H5token')) {
          //   mallApi({
          //     trxCode: 'XF174',
          //     longitude: res.result.location.lng,
          //     latitude: res.result.location.lat,
          //     cityName: res.result.ad_info.city,
          //     address: res.result.formatted_addresses.recommend,
          //   }).then(res => {
          //     this.$store.commit('user/SET_CUSTMSG', res.custMsg)
          //   })
          // }
        })
    }
  },

  // 设置用户当前城市
  async setLocationCity(context) {
    const key = context.state.mapKey
    var geolocation = new qq.maps.Geolocation(key, 'myapp')
    geolocation.getLocation(
      (position) => {
        descLocation(position.lat, position.lng)
      },
      (result) => {
        // Toast(`定位失败${JSON.stringify(result)}`)
      }
    )

    // app兼容定位方法
    // 扩展API加载完毕后调用onPlusReady回调函数
    document.addEventListener('plusready', onPlusReady, false)
    // 扩展API加载完毕，现在可以正常调用扩展API
    function onPlusReady() {
      plus.geolocation.getCurrentPosition(
        function (p) {
          // alert('Geolocation\nLatitude:' + p.coords.latitude + '\nLongitude:' + p.coords.longitude + '\nAltitude:' + p.coords.altitude);
          descLocation(p.coords.latitude, p.coords.longitude)
        },
        function (e) {
          alert('Geolocation error: ' + e.message)
        }
      )
    }

    // 获取经纬度详细信息
    function descLocation(lat, lng) {
      Vue.prototype
        .$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
          location: `${lat},${lng}`,
          output: 'jsonp', // output必须jsonp   不然会超时
          key
        })
        .then((res) => {
          context.commit('SET_LOCATIONCITY', res.result.ad_info.city)
        })
    }
  },

  // 获取系统配置
  async getSysName(context) {
    let agentId = localStorage.getItem('agent')
    if (!agentId) {
      Toast.fail('网页地址错误, 请输入正确地址')
      return
    }
    document.body.className = 'defaultThemes'

    await mallApi({
      agentId,
      trxCode: 'XF914'
    }).then((res) => {
      if (res.bwcName) {
        context.commit('SET_AGENTCONFIG', res)

        // 设置默认主题
        if (localStorage.getItem('theme')) {
          document.body.className = localStorage.getItem('theme')
        } else if (context.state.agentId === 'A10017309148') {
          document.body.className = res.USER_DEFAUIT_THEME || 'redThemes'
        } else {
          document.body.className = res.USER_DEFAUIT_THEME || 'defaultThemes'
        }
      }
    })
  },

  // 获取微信SDK
  async getWxSDK(context) {
    const { state, dispatch } = context
    let ismobileWeiXin = judgeTerrace()
    // 未配置公众号或非微信浏览器
    if (state.agentConfig.MP_APPID === 'NON' || !ismobileWeiXin) {
      return
    }
    let params = {
      trxCode: 'XF109',
      appId: state.agentConfig.MP_APPID,
      url: location.href.split('#')[0]
    }

    mallApi(params).then((res) => {
      if (res.signature) {
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: [
            'chooseWXPay',
            'chooseImage',
            'getLocalImgData',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareAppMessage'
          ], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp']
        })
        wx.ready(() => {
          // 公众号版标识
          context.commit('SET_VERSIONS', 'WeChat')
          localStorage.setItem('loginType', 'WeChat')

          dispatch('weChatShare')
        })
        wx.error(function (res) {
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
          console.log(res)
        })
      }
    })
  },

  async weChatShare(context) {
    const { state } = context
    let shareUrl = ''
    if (state.custMsg.custId) {
      shareUrl = `${state.agentConfig.SELF_DOMAIN_USER}/?agentId=${localStorage.getItem('agent')}&parentId=${state.custMsg.custId}`
    } else {
      shareUrl = `${state.agentConfig.SELF_DOMAIN_USER}/?agentId=${localStorage.getItem('agent')}`
    }
    let imgUrl =
      state.agentConfig.WECHAT_SHARE_IMG || 'https://xf-photo.oss-cn-guangzhou.aliyuncs.com/xifan-oss/day180/202207051656248axhN8Rj.jpg'
    let title = state.agentConfig.WECHAT_SHARE_TITLE || '请你免费吃外卖霸王餐'
    let desc = state.agentConfig.WECHAT_SHARE_DESC || '每天限量1000份'
    // 分享给朋友及QQ
    wx.updateAppMessageShareData({
      title,
      // title: state.agentConfig.bwcName,
      desc,
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: imgUrl
    })
    // 分享给朋友及QQ (旧版,即将废弃)
    wx.onMenuShareAppMessage({
      title,
      // title: state.agentConfig.bwcName,
      desc,
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: imgUrl
    })
    // 分享到朋友圈及 QQ 空间
    wx.updateTimelineShareData({
      title,
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: imgUrl
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
