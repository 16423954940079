import axios from 'axios'
import router from "../router";
import {
  Toast
} from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 20000 // request timeout 设置20秒超时
})
// 请求拦截
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('H5token')
    if (token) {
      config.headers['APPLETTOKEN'] = token
    }
    config.headers['Content-Type'] = "application/json;charset=utf-8"
    // 在发送请求之前做些什么
    return config
  },
  error => {
    // 处理请求错误
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    let data = response.data
    // if (data.rspCode === '0007' || data.rspCode === '0006') {
    if (data.rspCode === '0007') {
      Toast('Token已过期, 请重新登录')
      localStorage.removeItem('H5token')
      router.push({
        path: '/signin',
        query: {
          agentid: localStorage.getItem('agent')
        }
      })
    }
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service