<template>
  <div class="appPopup">
    <van-popup v-model="show" round class="phone-popup f40" :close-on-click-overlay="false">
      <div class="popup-title">
        系统更新
      </div>
      <div class="popup-content f38">
        <p>检测到存在新版本, 请待更新完成后继续体验</p>
        <p>(如发现更新失败, 请先卸载, 再重新下载安装)</p>
      </div>
      <a :href="downUrl" class="submitBut">立即体验</a>
    </van-popup>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'appPopup',
  data() {
    return {
      show: true,
      downUrl: '',
      versionCode: ''
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentId']),
  },
  created() {
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
  }
}
</script>

<style lang='less' scoped>
.phone-popup {
  width: 80vw;
}
.popup-title {
  margin: 4vw;
  font-size: 4vw;
  text-align: center;
}
.popup-content {
  padding: 12px;
}
.submitBut {
  display: block;
  color: #fff;
}
</style>