import store from "../store";
import th from "element-ui/src/locale/lang/th";
import wx from "weixin-js-sdk";
import { Toast } from 'vant'
export const parseFixed = function (val) {
  var tempVal = parseFloat(val).toFixed(3)
  var replace = tempVal.substring(0, tempVal.length - 1)
  return replace
}


/**
 * 获取地址栏对应参数
 * @param {String} url // 为空默认获取当前地址
 * @param {String} name // 不传name返回所有值，否则返回对应值
 * @returns
 */
export const getUrlParams = function (url, name) {
  if (url) {
    url = url.substr(url.indexOf('?'));
  } else {
    url = window.location.search
  }
  if (url.indexOf('?') == 1) {
    return false;
  }
  url = url.substr(1);
  url = url.split('&');
  var name = name || '';
  var nameres = {};
  // 获取全部参数及其值
  for (var i = 0; i < url.length; i++) {
    var info = url[i].split('=');
    nameres[info[0]] = decodeURI(info[1]);
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    return nameres[name]
  } else {
    return nameres;
  }
}

/**
 * 时间格式化
 * @param {Data} date 
 * @param {String} fmt 
 * @returns 
 */
export const dateFormat = function (date, fmt = 'YYYY-mm-dd HH:MM:SS') {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),
    "m+": (date.getMonth() + 1).toString(),
    "d+": date.getDate().toString(),
    "H+": date.getHours().toString(),
    "M+": date.getMinutes().toString(),
    "S+": date.getSeconds().toString()
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}

/**
 * 监听页面滚动
 */
export const getScrolltop = function () {
  const clientHeight = document.documentElement.clientHeight
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  return clientHeight + scrollTop
}
export const getScroll = function () {
  const scrollHeight = document.body.scrollHeight
  return scrollHeight - 100
}

/**
 * 查看存储天数
 */
export const getDay = function (begin) {
  var beginDate = new Date(begin)
  var lastDate = new Date()
  var date = (lastDate - beginDate) / 86400000
  return date
}

//检测平台
export const judgeTerrace = function () {
  let system = {}
  let ismobileWeiXin = false
  let p = navigator.platform;
  system.win = p.indexOf("Win") == 0;
  system.mac = p.indexOf("Mac") == 0;
  system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
  system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;

  let ua = navigator.userAgent.toLowerCase();
  if (system.win || system.mac || system.xll || system.ipad) {
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // console.log('在PC端微信上打开的');
      ismobileWeiXin = false

    } else {
      // console.log('在PC端非微信上打开的');
      ismobileWeiXin = false
    }
  } else {
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // console.log('在手机端微信上打开的');
      ismobileWeiXin = true

    } else {
      // console.log('在手机上非微信上打开的');
      ismobileWeiXin = false
    }
  }
  return ismobileWeiXin
}

// 获取时间差
export const settime = function (createTime, expireTime) {
  if (createTime > expireTime) {
    return '00:00:00'
  }

  var lasttime = (expireTime - createTime) * 1000 //时间差的毫秒数
  var days = Math.floor(lasttime / (24 * 3600 * 1000)) //计算出相差天数
  //计算出小时数
  var leave1 = lasttime % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  var min = Math.floor(leave2 / (60 * 1000))
  //计算相差秒数
  var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000)
  if (hours < 10) {
    hours = '0' + hours
  }
  if (min < 10) {
    min = '0' + min
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  // return days + "天" + hours + ":" + min + ':' + seconds
  return hours + ":" + min + ':' + seconds
}

// 修改页面标题  
export const changeTitle = function (title) {
  document.title = title;
  //ios兼容写法
  // let i = document.createElement('iframe');
  // i.style.display = 'none';
  // i.src = './request.js'; // 加载当前页面下一个体积小的资源，比如favicon.ico
  // i.onload = () => {
  //   setTimeout(() => {
  //     i.remove()
  //   }, 0);
  // }
  // document.body.appendChild(i);
}

export const isIOS = function (fn) {
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIOS) {
    return true;
  }
  return false;
}

/**
 * 判断是否是微信环境
 */
export const getIsWxClient = function () {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  }
  return false;
};

// 微信获取图片
export function wxChooseImage() {
  return new Promise((resolve, reject) => {
    // wx.checkJsApi({
    //   jsApiList: ['chooseImage', 'getLocalImgData'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
    //   success: function (res) {
    //     // 以键值对的形式返回，可用的 api 值true，不可用为false
    //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
    //     if (!res.checkResult.chooseImage || !res.checkResult.getLocalImgData) {
    //       reject()
    //     }
    //   }
    // });
    wx.chooseImage({
      count: 1, // 默认9
      sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res) {
        var localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片

        // 获取图片信息
        wx.getLocalImgData({
          localId: localIds[0], // 图片的localID
          success: function (res) {
            var localData = res.localData
            if (localData.indexOf('data:image') < 0) {
              //安卓处理
              localData = 'data:image/jpeg;base64,' + localData
            }
            resolve(localData) // localData是图片的base64数据
          },
          fail: (res) => {
            reject()
          },
        })
      },
      fail: (res) => {
        reject()
      },
    })
  })
}

/**
 * 判断url是否是http或https 
 * @param {string} path
 * @returns {Boolean}
 */
export function isHttp(url) {
  return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
}