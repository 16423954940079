<template>
  <div id="app">
    <div class="app-nav" v-if="!$route.meta.navShow && isApp">
      <div class="icon" @click="goBack()">
        <van-icon name="arrow-left" />
      </div>
      <div class="title">{{ $route.meta.title }}</div>
    </div>
    <!-- <template v-if="isRouterAlive">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </template> -->
    <router-view v-if="isRouterAlive" />
    <miantabbar v-show="$route.meta.navShow"></miantabbar>
  </div>
</template>

<script>
import { Toast } from 'vant'
import miantabbar from './components/Tabbar/miantabbar'
import { mallApi } from './api'
import { getUrlParams } from '@/utils/utils'
import { mapState, mapActions } from 'vuex'
import theAppPopup from './components/popup/appPopup'

export default {
  name: 'app',
  components: {
    miantabbar
  },
  data() {
    return {
      isRouterAlive: false
    }
  },
  computed: {
    ...mapState('user', ['address', 'agentConfig', 'isApp', 'appConfigJson', 'custMsg'])
  },
  async created() {
    if (window.plus) {
      this.onPlusReady()
    } else {
      document.addEventListener('plusready', this.onPlusReady, false)
    }
    let toast = Toast.loading({
      duration: 0, // 持续展示
      forbidClick: true,
      message: '登录中...'
    })

    try {
      this.getLocation()
      // 获取服务器配置文件
      await this.$http.get('/appConfig.json?random=' + new Date().getTime()).then((res) => {
        this.$store.commit('user/SET_APPCONFIGJSON', res.data)
      })
      await this.configAgent()
      await this.getSysName() // 获取租客配置
      if (localStorage.getItem('H5token')) {
        await this.getUserInfo()
      }
      await this.wechatLogin()
      // 获取微信SDK  TODO: 必须先获取租客配置信息后再调取SDK请求
      this.getWxSDK()
      this.redirectUrl()

      toast.clear()
      this.isRouterAlive = true
    } catch (error) {
      console.log(error)
      this.$toast(error.message)
      toast.clear()
      this.isRouterAlive = true
    }

    // 如定位失败, 显示用户位置
    setTimeout(() => {
      if (!this.address) {
        const custMsg = this.custMsg
        if (custMsg.latitude && custMsg.longitude && custMsg.cityName && custMsg.address) {
          let location = {}
          location.longitude = custMsg.longitude
          location.latitude = custMsg.latitude
          this.$store.commit('user/SET_LOCATION', location)
          this.$store.commit('user/SET_CITYNAME', custMsg.cityName)
          this.$store.commit('user/SET_ADDRESS', custMsg.address)
        }
      }
    }, 8000)
  },
  methods: {
    ...mapActions('user', ['getUserInfo', 'getLocation', 'getSysName', 'getWxSDK']),

    async configAgent() {
      let url = location.hrefs
      url = 'https://wxmp.youfanshenghuo.com/?agentid=A1817967050' // 有范
      // url = 'https://wxmp.youfanshenghuo.com/?agentid=A10017309148' //
      // url = 'https://wxmp.youfanshenghuo.com/?agentid=A10010209131' // 外卖情报所

      // 测试环境
      // url = 'https://wxmp.youfanshenghuo.com/?agentid=A10002014237' // agentbwc
      // url = 'https://wxmp.youfanshenghuo.com/?agentid=A10002109406' // 测试环境2

      if (process.env.NODE_ENV === 'production') {
        url = location.hrefs
      }

      // 上海冯总app定制
      // url = 'https://wxmp.youfanshenghuo.com/?agentid=A10017309148'

      const urlParams = getUrlParams(url)
      // H5版
      // TODO 历史遗留问题, 同时判断agentid和agentId
      if (urlParams.hasOwnProperty('agentid') || urlParams.hasOwnProperty('agentId')) {
        let agentId = urlParams.agentId || urlParams.agentid
        if (agentId !== localStorage.getItem('agent')) {
          localStorage.removeItem('H5token')
          localStorage.removeItem('theme')
          // this.$router.push({ path: '/' })
        }
        this.$store.commit('user/SET_VERSIONS', 'H5')
        localStorage.setItem('loginType', 'H5')
        this.$store.commit('user/SET_AGENTID', agentId)
        localStorage.setItem('agent', agentId)
      }

      // 小程序版
      if (urlParams.versions === 'applet') {
        this.$store.commit('user/SET_VERSIONS', 'applet')
        localStorage.setItem('loginType', 'applet')
        localStorage.setItem('H5token', urlParams.token)
        let location = {
          latitude: urlParams.latitude,
          longitude: urlParams.longitude
        }
        this.$store.commit('user/SET_LOCATION', location)
      }

      // 用户推广
      if (urlParams.hasOwnProperty('parentId')) {
        sessionStorage.setItem('parentId', urlParams.parentId)
      }

      // 推广员推广
      if (urlParams.hasOwnProperty('promoterId')) {
        sessionStorage.setItem('promoterId', urlParams.promoterId)
      }

      // 自定义域名处理
      let domainAgent = this.appConfigJson.domainAgentId.find((v) => v.domain === window.location.host)
      if (domainAgent && domainAgent.agentId) {
        this.$store.commit('user/SET_AGENTID', domainAgent.agentId)
        localStorage.setItem('agent', domainAgent.agentId)
      }

      // 张总定制版
      if (window.location.host === 'user888.canyinboo.com') {
        this.$store.commit('user/SET_CUSTOMZHANG', true)
      }

      // 1367定制要求-重定向
      if (urlParams.agentId === 'A10022609113' && window.location.host !== 'bwc.fanbaichi.xyz') {
        let path = window.location.href.replace(window.location.host, 'bwc.fanbaichi.xyz')
        window.location.href = path
        return
      }
    },

    // 公众号版登录
    async wechatLogin() {
      let url = location.hrefs

      // 公众号版
      const urlParams = getUrlParams(url)
      if (!urlParams.hasOwnProperty('code')) {
        return
      }

      let params = {
        jsCode: urlParams.code,
        appId: this.agentConfig.MP_APPID,
        agentId: localStorage.getItem('agent'),
        trxCode: 'XF108'
      }

      let parentId = localStorage.getItem('parentId')
      if (parentId) {
        params.parentId = parentId
        params.type = '00'
      }

      let promoterId = localStorage.getItem('promoterId')
      if (promoterId) {
        params.parentId = promoterId
        params.type = '03'
      }

      await mallApi(params).then((res) => {
        if (res.APPLETTOKEN) {
          if (parentId) {
            this.$toast('登陆成功, 推广id: ' + parentId)
            localStorage.removeItem('parentId')
            sessionStorage.removeItem('promoterId')
          }
          localStorage.setItem('H5token', res.APPLETTOKEN)
          this.$store.commit('user/SET_CUSTMSG', res.custMsg)

          // // 获取定位信息
          // const custMsg = res.custMsg
          // if (custMsg.latitude && custMsg.longitude && custMsg.cityName && custMsg.address) {
          //   let location = {}
          //   location.longitude = custMsg.longitude
          //   location.latitude = custMsg.latitude
          //   this.$store.commit('user/SET_LOCATION', location)
          //   this.$store.commit('user/SET_CITYNAME', custMsg.cityName)
          //   this.$store.commit('user/SET_ADDRESS', custMsg.address)
          // }
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },

    // 重定向
    redirectUrl() {
      if (!localStorage.getItem('H5token')) return
      let redirect = localStorage.getItem('redirectUrl')
      if (redirect) {
        let url = `${this.agentConfig.SELF_DOMAIN_USER}/#${redirect}`
        window.location.href = url
        localStorage.removeItem('redirectUrl')
      }
    },

    // APP --- plus加载完成
    onPlusReady() {
      this.$store.commit('user/SET_ISAPP', true)
      this.$store.commit('user/SET_VERSIONS', 'app')
      plus.navigator.setStatusBarBackground('#F5F5F5') //背景色
      plus.navigator.setStatusBarStyle('dark') //字体色
      this.getNativeVersion()
    },

    // 获取当前版本号
    getNativeVersion() {
      let that = this
      plus.runtime.getProperty(plus.runtime.appid, function (inf) {
        that.checkUpdate(inf.versionCode)
        localStorage.setItem('nativeVersion', inf.version)
      })
    },

    // 检查当前版本号
    checkUpdate(versionCode) {
      let agentId = localStorage.getItem('agent', agentId)
      let params = {
        trxCode: 'XF916',
        agentId,
        osType: 'ANDROID'
      }
      mallApi(params).then((res) => {
        if (res.data.version > versionCode) {
          // let url = `https://wxmp.youfanshenghuo.com/?agentId=${agentId}#/appDownload`
          theAppPopup({ downUrl: res.data.appUrl, versionCode })
        }
      })
    },

    // 返回上一页
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
@import url('./styles/index.less');
@import url('./styles/themes/index.less');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3.6vw;
  color: #333;
}
.app-contianer::-webkit-scrollbar {
  /* 隐藏滚动条，但依旧具备可以滚动的功能 */
  display: none;
}
.app-nav {
  position: relative;
  height: 12vw;
  width: 100%;
  background: #f5f5f5;

  .icon {
    position: absolute;
    width: 10vw;
    height: 12vw;
    line-height: 12vw;
    text-align: center;
    font-size: 5vw;
  }
  .title {
    line-height: 12vw;
    text-align: center;
  }
}
</style>
