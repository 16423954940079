import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
    meta: {
      navShow: true,
      title: ' ',
      keepAlive: true
    }
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/Home/Apply.vue'),
    meta: {
      navShow: false,
      title: '报名试吃'
    }
  },
  {
    path: '/cityList',
    name: 'CityList',
    component: () => import('../components/CityList.vue'),
    meta: {
      navShow: false,
      title: '城市选择'
    }
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../components/Map.vue'),
    meta: {
      navShow: false,
      title: '地图选点'
    }
  },
  {
    path: '/shopList',
    name: 'ShopList',
    component: () => import('../views/shop/ShopList.vue'),
    meta: {
      navShow: true,
      title: '商店列表'
    }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/team/Index.vue'),
    meta: {
      navShow: true,
      title: '赚钱'
    }
  },
  {
    path: '/setLogon',
    name: 'SetLogon',
    component: () => import('../views/user/compent/setLogon.vue'),
    meta: {
      navShow: false,
      title: '绑定支付宝账号'
    }
  },
  {
    path: '/signin',
    name: 'signIn',
    component: () => import('../views/signIn.vue'),
    meta: {
      navShow: false,
      title: '登录'
    }
  },
  {
    path: '/screenshot',
    name: 'screenshot',
    component: () => import('../views/Home/screenshot.vue'),
    meta: {
      navShow: false,
      title: '上传截图'
    }
  },
  {
    path: '/editUserinfo',
    name: 'editUserinfo',
    component: () => import('../views/user/EditUserinfo.vue'),
    meta: {
      navShow: false,
      title: '更改用户信息'
    }
  },
  {
    path: '/detailed',
    name: 'detailed',
    component: () => import('../views/other/Detailed.vue'),
    meta: {
      navShow: false,
      title: '详细步骤'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/user.vue'),
    meta: {
      navShow: true,
      title: '我的'
    }
  },
  {
    path: '/plusevip',
    name: 'plusevip',
    component: () => import('../views/user/plusvip.vue'),
    meta: {
      navShow: false,
      title: 'puls会员'
    }
  },
  {
    path: '/ourteam',
    name: 'ourteam',
    component: () => import('../views/user/ourTeam.vue'),
    meta: {
      navShow: false,
      title: '我的团队'
    }
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/user/compent/wthdrawal.vue'),
    meta: {
      navShow: false,
      title: '提现'
    }
  },
  {
    path: '/promoter',
    name: 'promoter',
    component: () => import('../views/user/Promoter.vue'),
    meta: {
      navShow: false,
      title: '提现'
    }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/orderList/orderList.vue'),
    meta: {
      navShow: true,
      title: '试吃清单'
    }
  },
  {
    path: '/orderDesc',
    name: 'orderDesc',
    component: () => import('../views/orderList/orderDesc.vue'),
    meta: {
      navShow: false,
      title: '试吃清单'
    }
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/user/compent/contactus'),
    meta: {
      navShow: false,
      title: '联系客服'
    }
  },
  {
    path: '/puertion',
    name: 'puertion',
    component: () => import('../views/other/puertion.vue'),
    meta: {
      navShow: false,
      title: '试吃团规则'
    }
  },
  {
    path: '/ticky',
    name: 'ticky',
    component: () => import('../views/user/compent/tikcy.vue'),
    meta: {
      navShow: true,
      title: '邀请海报'
    }
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../views/user/compent/phone.vue'),
    meta: {
      navShow: false,
      title: '更改手机号'
    }
  },
  {
    path: '/changeTheme',
    name: 'changeTheme',
    component: () => import('../views/user/ChangeTheme.vue'),
    meta: {
      navShow: false,
      title: '切换主题'
    }
  },
  {
    path: '/cancellation',
    name: 'cancellation',
    component: () => import('../views/user/compent/cancellation.vue'),
    meta: {
      navShow: false,
      title: '注销账户'
    }
  },
  {
    path: '/updateUser',
    name: 'updateUser',
    component: () => import('../views/user/compent/updateUser.vue'),
    meta: {
      navShow: false,
      title: '更新信息'
    }
  },
  {
    path: '/appDownload',
    name: 'AppDownload',
    component: () => import('../views/other/AppDownload.vue'),
    meta: {
      navShow: false,
      title: 'app下载'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/user/settings.vue'),
    meta: {
      navShow: false,
      title: '个人设置'
    }
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    component: () => import('../views/user/setPassword.vue'),
    meta: {
      navShow: false,
      title: '更换登录密码'
    }
  },
  {
    path: '/tariffe',
    name: 'tariffe',
    component: () => import('../views/application/tariffe/index.vue'),
    meta: {
      navShow: false,
      title: '话费充值'
    }
  },
  {
    path: '/tariffeRecord',
    name: 'tariffeRecord',
    component: () => import('../views/application/tariffe/tariffeRecord.vue'),
    meta: {
      navShow: false,
      title: '话费充值记录'
    }
  },
  {
    path: '/electricity',
    name: 'electricity',
    component: () => import('../views/application/electricity/index.vue'),
    meta: {
      navShow: false,
      title: '电费缴纳'
    }
  },
  {
    path: '/electricityRecord',
    name: 'electricityRecord',
    component: () => import('../views/application/electricity/electricityRecord.vue'),
    meta: {
      navShow: false,
      title: '电费缴纳记录'
    }
  },
  {
    path: '/doorList',
    name: 'doorList',
    component: () => import('../views/application/electricity/doorList.vue'),
    meta: {
      navShow: false,
      title: '选择户号'
    }
  },
  {
    path: '/bindDoorNo',
    name: 'bindDoorNo',
    component: () => import('../views/application/electricity/bindDoorNo.vue'),
    meta: {
      navShow: false,
      title: '添加户号'
    }
  },
  {
    path: '/editDoorNo',
    name: 'editDoorNo',
    component: () => import('../views/application/electricity/EditDoorNo.vue'),
    meta: {
      navShow: false,
      title: '编辑户号'
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('../views/cooperation/Index.vue'),
    meta: {
      navShow: false,
      title: '合作中心'
    }
  },
  {
    path: '/coopSubmit',
    name: 'coopSubmit',
    component: () => import('../views/cooperation/Submit.vue'),
    meta: {
      navShow: false,
      title: '合作中心'
    }
  },
  {
    path: '/customElm',
    name: 'customElm',
    component: () => import('../views/other/CustomElm.vue'),
    meta: {
      navShow: true,
      title: '立减餐'
    }
  },
  {
    path: '/billAccount',
    name: 'BillAccount',
    component: () => import('../views/other/BillAccount.vue'),
    meta: {
      navShow: false,
      title: '对账单列表'
    }
  },
  {
    path: '/billList',
    name: 'BillList',
    component: () => import('../views/bill/BillList.vue'),
    meta: {
      navShow: false,
      title: '对账单列表'
    }
  },
  {
    path: '/openApplet',
    name: 'OpenApplet',
    component: () => import('../views/other/OpenApplet.vue'),
    meta: {
      navShow: false,
      title: '跳转小程序'
    }
  },
  {
    path: '*',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
    meta: {
      navShow: true,
      title: ' ',
      keepAlive: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
