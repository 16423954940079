import Vue from 'vue';
import store from '../../store'
import appPopup from './appPopup.vue'

let theAppPopup = function (content) {
  let Constructor = Vue.extend(appPopup);
  return new Promise((res, rej) => {
    let DOM = new Constructor({
      el: document.createElement('div'),
      store
    })
    document.body.appendChild(DOM.$el);

    DOM.downUrl = content.downUrl
    DOM.versionCode = content.versionCode
    DOM.ok = function (value) {
      res(value)
    }
    DOM.cancel = function (value) {
      document.body.removeChild(DOM.$el);
    }
  })
}
export default theAppPopup;
