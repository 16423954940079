import Vue from 'vue';
import { tfoodApi, mallApi } from "@/api";
import { Toast } from 'vant'
import { judgeTerrace } from '@/utils/utils'


const state = {
  doorList: [],
  defaultDoor: false,
  editDoorData: {}
}

const mutations = {
  SET_DOORLIST: (state, doorList) => {
    state.doorList = doorList
  },
  SET_DEFAULTDOOR: (state, defaultDoor) => {
    state.defaultDoor = defaultDoor
  },
  SET_EDITDOORDATA: (state, editDoorData) => {
    state.editDoorData = editDoorData
  },

}

const actions = {
  // 获取户号列表
  async getDoorList(context) {
    let params = {
      trxCode: 'XF331',
      acctType: 'ELECTRIC'
    }
    await mallApi(params).then(res => {
      const { rspCode, rspMsg, data } = res
      if (rspCode === '0000') {
        context.commit('SET_DOORLIST', data)
        let defaultDoor = data.find(v => v.isDefault === 'Y')
        if (!context.state.defaultDoor) {
          context.commit('SET_DEFAULTDOOR', defaultDoor)
        }
      } else {
        Toast(rspMsg)
      }
    })
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}