import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipBoard from 'vue-clipboard2'
import VueCanvasPoster from 'vue-canvas-poster'
import vant from 'vant'
import 'vant/lib/index.css'
import { Toast } from 'vant'
import { VueJsonp } from 'vue-jsonp'
// 引入插件
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(vant)
Vue.use(Toast)
Vue.prototype.$http = axios
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(ElementUI)
Vue.use(VueClipBoard)
Vue.use(VueCanvasPoster)
Vue.use(VueJsonp)
// 注册插件
Vue.use(VueLazyload)

router.beforeEach((to, from, next) => {
  // 滚动条置顶
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  // 兼容IE
  window.scrollTo(0, 0)
  // 修改页面标题
  if (to.meta.title) {
    document.title = to.meta.title
  }

  let passPath = ['/', '/signin', '/map', '/cityList', '/appDownload']
  if (passPath.indexOf(to.path) >= 0) {
    next()
  } else {
    if (localStorage.getItem('H5token')) {
      next()
    } else {
      next({
        path: '/signin',
        query: { agentid: localStorage.getItem('agent'), redirect: to.fullPath }
      })
    }
  }
})

// 设置定制版页面logo
document.querySelectorAll("link[rel*='icon']").forEach((item) => {
  // 众客科技
  if (window.location.host === 'user888.canyinboo.com') {
    item.href = 'favicon_ZBWL.ico'
  }
})

// input 输入金额限制
Vue.directive('enterMoney', {
  // v-enter-money
  bind: function (el, { value = 2 }) {
    el = el.nodeName == 'INPUT' ? el : el.children[0]
    let RegStr = value == 0 ? `^[\\+\\-]?\\d+\\d{0,0}` : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
    el.addEventListener('keyup', function () {
      el.value = el.value.match(new RegExp(RegStr, 'g'))
      el.dispatchEvent(new Event('input'))
    })
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
